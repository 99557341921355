
import {
    computed, defineComponent, reactive, onBeforeMount,
} from 'vue';
import RepairPart from '@/domain/Repair/RepairPart';
import { TableDefinition } from '@/types';
import RepairPartService from '@/services/RepairPartService';
import Screen from '@/components/layout/Screen.vue';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Permissions from '@/services/permissions/Permissions';
import EntityType from '@/domain/enums/EntityTypes';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import coreStore from '@/store/CoreStore';

type State = {
    repairParts: Array<RepairPart>;
    loading: boolean;
};

export default defineComponent({
    name: 'repair-parts-screen',
    components: {
        Screen,
    },
    setup() {
        const repairPartService = new RepairPartService();
        const { confirm } = useDialogBox();
        const { profileStore } = coreStore.getInstance();

        const state = reactive<State>({
            repairParts: [],
            loading: false,
        });

        const table = computed(
            (): TableDefinition<RepairPart> => ({
                items: state.repairParts,
                key: 'repairPartsList',
                name: getTitleCaseTranslation('core.domain.repairParts'),
                columnDefinition: [
                    {
                        label: getTitleCaseTranslation('core.domain.partNumber'),
                        key: 'partNumber',
                        searchable: true,
                        width: '20%',
                    },
                    {
                        key: 'description',
                        label: getTitleCaseTranslation('core.domain.description'),
                        searchable: true,
                        width: '30%',
                    },
                    {
                        key: 'reorderPoint',
                        label: getTitleCaseTranslation('core.domain.reorderPoint'),
                        searchable: false,
                        width: '15%',
                    },
                    {
                        key: 'quantity',
                        label: getTitleCaseTranslation('core.domain.quantity'),
                        searchable: false,
                        width: '15%',
                    },
                    {
                        key: 'billingPrice',
                        label: getTitleCaseTranslation('core.domain.billingPrice'),
                        searchable: false,
                        width: '20%',
                    },
                ],
            }),
        );

        async function loadRepairParts() {
            state.loading = true;
            const response = await repairPartService.getAllRepairPartsWithLocation(profileStore.userLocation.id);
            if (response) {
                state.repairParts = response;
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            loadRepairParts();
        });

        function openAdd() {
            router.push({
                name: MasterDataRouteTypes.REPAIR_PART.ADD,
            });
        }

        function openEdit(repairPart: RepairPart) {
            router.push({
                name: MasterDataRouteTypes.REPAIR_PART.EDIT,
                params: { repairPartId: repairPart.id.toString() },
            });
        }

        async function openDelete(repairPart: RepairPart) {
            if (await confirm({ title: getTranslation('core.common.areYouSure'), message: getTranslation('core.domain.repairPartConfirmDelete') })) {
                const response = await repairPartService.deleteRepairPart(repairPart.id);
                if (response) {
                    loadRepairParts();
                }
            }
        }

        async function openRepairPartHistory(repairPart: RepairPart) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.REPAIR_PART, entityId: repairPart.id },
            });
        }

        return {
            state,
            table,
            openAdd,
            openEdit,
            openDelete,
            openRepairPartHistory,
            getTitleCaseTranslation,
            getTranslation,
            Permissions,
        };
    },
});
